import { ref, provide, onBeforeMount } from "vue";

const pluginData = ref({});
const ajaxUrl = ref(null);
const vueWrappingClass = ref(null);

const usePluginData = (plugin_data_obj_key) => {

    provide('pluginData', pluginData);
    provide('ajaxUrl', ajaxUrl);
    provide('vueWrappingClass', vueWrappingClass);

    onBeforeMount(() => {
        if (!window.hasOwnProperty(plugin_data_obj_key)) {
            return;
        }

        pluginData.value = window[plugin_data_obj_key];

        if (window[plugin_data_obj_key].hasOwnProperty('ajax_url')) {
            ajaxUrl.value = window[plugin_data_obj_key].ajax_url;
        }

        if (window[plugin_data_obj_key].hasOwnProperty('vue_wrapping_class')) {
            vueWrappingClass.value = window[plugin_data_obj_key].vue_wrapping_class;
        }
    });

    return {
        pluginData,
        ajaxUrl,
        vueWrappingClass,
    };
};

export default usePluginData;